<template>
  <div>
    <div v-if="isLoggedIn">
      <div class="addOrder">
        <h2>Добавить Клинера </h2>
        <div class="">
          <input v-bind:class="{ error: ErrorCleanerName }" v-model="newCleaner" placeholder="Имя Фамилия">
        </div>
        <button @click="addCleaner(newCleaner)">Добавить</button>
      </div>

      <div class="addOrder">
        Показать доход клинеров за
        <select v-model="getIncomeMonth">
          <option value="1">Январь</option>
          <option value="2">Февраль</option>
          <option value="3">Март</option>
          <option value="4">Апрель</option>
          <option value="5">Май</option>
          <option value="6">Июнь</option>
          <option value="7">Июль</option>
          <option value="8">Август</option>
          <option value="9">Сентябрь</option>
          <option value="10">Октябрь</option>
          <option value="11">Ноябрь</option>
          <option value="12">Декабрь</option>
        </select>
        <select v-model="getIncomeYear">
          <option v-for="year in yearsList" :key="year" :value="year">{{ year }}</option>
        </select>
        <h2 v-if="orders">
          {{ sumInMonth({ getIncomeMonth, getIncomeYear }) }}
        </h2>
      </div>

      <div class="table">
        <table>
          <thead>
            <tr>
              <th>Клинер</th>
              <th>ЗП за <select v-model="getIncomeMonth">
                  <option value="1">Январь</option>
                  <option value="2">Февраль</option>
                  <option value="3">Март</option>
                  <option value="4">Апрель</option>
                  <option value="5">Май</option>
                  <option value="6">Июнь</option>
                  <option value="7">Июль</option>
                  <option value="8">Август</option>
                  <option value="9">Сентябрь</option>
                  <option value="10">Октябрь</option>
                  <option value="11">Ноябрь</option>
                  <option value="12">Декабрь</option>
                </select>
                <select v-model="getIncomeYear">
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </th>
              <th>Кол-во заказов</th>
              <th>Скрыть</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cleaner in cleaners" :cleaner="cleaner" :key="cleaner.ID">
              <td>{{ cleaner.CleanerName }}</td>
              <td v-if="orders">{{ getSalaryMonth({ getIncomeMonth, getIncomeYear }, cleaner.ID) }}</td>
              <td v-if="orders">{{ getCountCleanerOrders({ getIncomeMonth, getIncomeYear }, cleaner.ID) }}</td>
              <td>
                <div @click="changeCleanerStatus(cleaner.ID, cleaner.hidden)" v-if="cleaner.hidden == 1"
                  class="square active">✔</div>
                <div @click="changeCleanerStatus(cleaner.ID, cleaner.hidden)" v-else class="square"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from 'axios';
const date = new Date();
const todayMonth = date.getMonth() + 1;
const todayYear = date.getFullYear();

export default {
  components: {
  },
  data() {
    return {
      sortBy: 'Номер',
      sortType: true,
      newCleaner: '',
      getIncomeMonth: todayMonth,
      getIncomeYear: todayYear,
      income: '0',

      login: '',
      password: '',
    }
  },
  methods: {
    async changeCleanerStatus(cleanerID, status) {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'changeCleanerStatus',
              cleanerID: cleanerID,
              status: status,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )

        if (response.data.status === 'success') {
          let cleanerID = response.data.cleanerID;
          let to_change = response.data.to_change;

          for (let i in this.cleaners) {
            if (this.cleaners[i].ID === cleanerID) {
              this.cleaners[i].hidden = to_change;
              break;
            }
          }
        } else {
          alert('Ошибка при изменении статуса');
          return false;
        }

      } catch (e) {
        alert('Ошибка при изменении статуса')
        console.log(e);
      }
    },
    ...mapActions({
      entry: 'post/entry',
      keyExist: 'post/keyExist',
      getCleaners: 'post/getCleaners',
      addCleaner: 'post/addCleaner',
      getOrders: 'post/getOrders',
    }),

    sortedBy(event) {
      const isString = typeof this.cleaners[0][event] === "string"; // Проверяем, строка ли это
      const sortOrder = this.sortType ? 1 : -1; // Устанавливаем порядок сортировки

      this.cleaners.sort((a, b) => {
        if (isString) {
          return sortOrder * a[event].localeCompare(b[event]);
        } else {
          return sortOrder * (a[event] - b[event]);
        }
      });

      this.sortType = !this.sortType; // Меняем порядок на противоположный
      this.sortBy = event;
    },


    sumInMonth(getIncome) {
      const { getIncomeMonth, getIncomeYear } = getIncome; // Деструктуризация для удобства
      let totalSalary = 0;

      this.orders.forEach(order => {
        const [orderInfo, cleaners] = order; // Деструктуризация массива

        if (!orderInfo || !cleaners) return; // Пропустить, если данные некорректные

        const date = new Date(orderInfo[0].OrderDate);
        const orderMonth = date.getMonth() + 1;
        const orderYear = date.getFullYear();

        if (getIncomeMonth === orderMonth && getIncomeYear === orderYear) {
          // Суммируем зарплаты всех клинеров
          totalSalary += cleaners.reduce((sum, cleaner) => sum + Number(cleaner.CleanerSalary), 0);
        }
      });

      return totalSalary;
    },


    getSalaryMonth(obj, cleanerID) {
      const { getIncomeMonth, getIncomeYear } = obj; // Деструктуризация для удобства
      let totalSalary = 0;

      this.orders.forEach(order => {
        const [orderInfo, cleaners] = order; // Деструктуризация массива

        if (!orderInfo || !cleaners) return; // Пропустить, если данные некорректные

        const date = new Date(orderInfo[0].OrderDate);
        const orderMonth = date.getMonth() + 1;
        const orderYear = date.getFullYear();

        if (getIncomeMonth === orderMonth && getIncomeYear === orderYear) {
          // Суммируем зарплаты для указанного CleanerID
          totalSalary += cleaners
            .filter(cleaner => cleaner.CleanerID === cleanerID)
            .reduce((sum, cleaner) => sum + Number(cleaner.CleanerSalary), 0);
        }
      });

      return totalSalary;
    },


    getCountCleanerOrders(obj, cleanerID) {
      const { getIncomeMonth, getIncomeYear } = obj; // Деструктуризация для удобства
      let count = 0;

      this.orders.forEach(order => {
        const [orderInfo, cleaners] = order; // Деструктуризация массива

        if (!orderInfo || !cleaners) return; // Пропустить, если данные некорректные

        const date = new Date(orderInfo[0].OrderDate);
        const orderMonth = date.getMonth() + 1;
        const orderYear = date.getFullYear();

        if (getIncomeMonth === orderMonth && getIncomeYear === orderYear) {
          count += cleaners.filter(cleaner => cleaner.CleanerID === cleanerID).length;
        }
      });

      return count;
    },

  },
  mounted() {
    this.getCleaners();
    this.getOrders();
  },
  computed: {
    yearsList() {
      const currentYear = new Date().getFullYear(); // Получить текущий год
      const startYear = 2021; // Начальный год
      return Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
    },
    ...mapState({
      orders: state => state.post.orders,
      isLoggedIn: state => state.post.isLoggedIn,
      error: state => state.post.error,
      searchQuery: state => state.post.searchQuery,

      ErrorCleanerName: state => state.post.ErrorCleanerName,
      cleaners: state => state.post.cleaners,

    }),
    ...mapGetters({})
  }

}
</script>

<style scoped>
.table {
  overflow: auto;
  padding-bottom: 5px;
  margin-top: 10px;
  border-radius: 30px;
}

th {
  cursor: pointer;
  padding: 0px 5px 0px 5px;
}

th:hover {
  background: #ebebeb;
}

table {
  width: 100%;
  text-align: center;
  border-spacing: unset;
  border-radius: 10px;
  white-space: nowrap;
}

table thead {
  background: #d3d3d3;
  height: 60px;
}


.sortDown:after {
  content: "▼";
  font-size: 10px;
  color: #1E90FF;
}

.sortUp:after {
  content: "▲";
  font-size: 10px;
  color: #1E90FF;
}

.addOrder {
  border-radius: 30px;
  border: 1px solid gray;
  padding: 20px;
  margin-top: 20px;
}

.error {
  border: 2px solid red;
}

input {
  padding: 5px;
  border-radius: 30px;
  border: 1px solid gray;
  margin: 10px;
  max-height: 40px;
}

button {
  padding: 10px;
  width: 150px;
  border-radius: 20px;
  border: none;
  background: #0072ff;
  color: white;
}

thead tr {
  background: #d3d3d3;
  border-radius: 20px;
  border: none;
  height: 35px;
}

tbody tr {
  background: #ebebeb;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.table tr:hover td {
  background: #ffffff;
}

.table tr:hover td:after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 105%;
}

/* Рамка слева у первой ячейки */
.table tr:hover td:first-child:after {
  border-left: 3px solid orange;
}

/* Рамка справа у последний ячейки */
.table tr:hover td:last-child:after {
  border-right: 3px solid orange;
  width: auto;
}

tr td:first-child {
  position: sticky;
  z-index: 1;
  left: 0;
  background: #ebebeb;
  border-right: 3px solid #d3d3d3;
}

.modal {
  width: 310px;
  height: 300px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 20px;
}

.pageEntry {
  display: flex;
  align-items: center;
  height: 100vh;
}

.pageEntry input {
  border: none;
  box-shadow: rgb(100 100 111 / 30%) 0px 0px 4px 1px;
  border-radius: 30px;
  padding: 10px;
}

.pageEntry button {
  text-align: center;
  padding: 15px;
  border-radius: 30px;
  background: #d3d3d3;
  border: none;
  font-weight: bold;
}

.pageEntry button:hover {
  background: #3572ff;
  color: white;
}

.square {
  border: 2px solid black;
  z-index: 3;
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all .3s;
  cursor: pointer;
}

.square:hover {
  border: 2px solid #1451ea;
  color: #1451ea;
}

.square.active {
  border: 2px solid #a2a2a2;
  color: #a2a2a2;
  opacity: .3;
}

.square.active:hover {
  border: 2px solid red;
  color: red;
}
</style>
