<template>
  <div>
    <transition name="fade">
      <div class="edit_modal_back" v-if="edit_modal">
        <div class="edit_modal">
          <div>
            <h2>Редактирование</h2>
            <span @click="edit_modal = false" class="modal_close">
              ✖
            </span>
          </div>
          <div class="modal_create_info">
            <label>
              ID
              <input v-model="edit_ID" type="number">
            </label>
            <label>
              Фамилия имя
              <input v-model="edit_fio" type="text">
            </label>
            <label>
              Адрес
              <input v-model="edit_address" type="text">
            </label>
            <label>
              Статус
              <select v-model="edit_status">
                <option value="0">Нет</option>
                <option value="1">Клиент думает</option>
                <option value="2">Недозвон</option>
                <option value="5">Созвониться</option>
                <option value="3">Просмотр</option>
                <option value="4">В работе</option>
              </select>
            </label>
            <label>
              Комментарий
              <input v-model="edit_comment" type="text">
            </label>
            <label>
              Время
              <input v-model="edit_time" type="time">
            </label>
            <label>
              Дата
              <input v-model="edit_date" type="date">
            </label>
            <label>
              В работе
              <select v-model="edit_in_work">
                <option value="0">Нет</option>
                <option value="1">Да</option>
              </select>
            </label>
          </div>
          <button @click="validateData(true)" :class="{ send_request: send_request }">
            <span v-if="send_request">Загрузка...</span>
            <span v-else>Сохранить</span>
          </button>
          <button style="margin-top: 20px" @click="delete_plan(this.edit_plan_ID)">
            <span>Удалить</span>
          </button>


          <span class="error_mess" v-if="error_mess">{{ error_mess }}</span>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="modal_create_back" v-if="modal_create">
        <div class="modal_create">
          <div>
            <h2>Добавить задачу</h2>
            <span @click="modal_create = false" class="modal_close">
              ✖
            </span>
          </div>
          <div class="modal_create_info">
            <label>
              ID
              <input v-model="create_ID" type="number">
            </label>
            <span v-if="dublicateID" class="error_mess">Такой ID уже есть</span>
            <label>
              Фамилия имя
              <input v-model="create_fio" type="text">
            </label>
            <label>
              Адрес
              <input v-model="create_address" type="text">
            </label>
            <label>
              Статус
              <select v-model="create_status">
                <option value="0">Нет</option>
                <option value="1">Клиент думает</option>
                <option value="2">Недозвон</option>
                <option value="5">Созвониться</option>
                <option value="3">Просмотр</option>
                <option value="4">В работе</option>
              </select>
            </label>
            <label>
              Комментарий
              <input v-model="create_comment" type="text">
            </label>
            <label>
              Время
              <input v-model="create_time" type="time">
            </label>
            <label>
              Дата
              <input v-model="create_date" type="date">
            </label>
            <label>
              В работе
              <select v-model="create_in_work">
                <option value="0">Нет</option>
                <option value="1">Да</option>
              </select>
            </label>
          </div>
          <button @click="validateData()" :class="{ send_request: send_request }">
            <span v-if="send_request">Загрузка...</span>
            <span v-else>Добавить</span>
          </button>
          <span class="error_mess" v-if="error_mess">{{ error_mess }}</span>
        </div>
      </div>
    </transition>
    <div class="create_planer">
      <button @click="modal_create = true">Создать</button>
    </div>

    <div class="search_plans">
      <input type="text" v-model="search_query" placeholder="Поиск по ID или Адресу">
    </div>

    <div v-if="isLoggedIn">
      <div class="planer_table">
        <div class="planer_table_body" v-if="plans">
          <div v-for="elem in searchArray" class="planer_table_body_elem" @click="open_edit(elem)"
            :class="{ orange: checkDate(elem.planerDate) == 2, red: checkDate(elem.planerDate) == 1 }">
            <span v-if="elem.plan_ID" class="elem_id">ID: {{ elem.plan_ID }}</span>
            <div><span class="elem_title">ФИО:</span> {{ elem.fio }}</div>
            <div><span class="elem_title">Адрес:</span> {{ elem.address }}</div>
            <div><span class="elem_title">Комментарий:</span> {{ elem.comment }}</div>
            <div class="plan_date">
              {{ getFormatDate(elem.planerDate) }}, {{ elem.planerTime }}
            </div>
            <div class="in_work" v-if="elem.in_work == 1">✔</div>
            <span v-if="elem.status == 1" class="elem_status">Клиент думает</span>
            <span v-if="elem.status == 2" class="elem_status">Недозвон</span>
            <span v-if="elem.status == 3" class="elem_status">Просмотр</span>
            <span v-if="elem.status == 4" class="elem_status green_text">В работе</span>
            <span v-if="elem.status == 5" class="elem_status red_text">Созвониться</span>
          </div>
        </div>
        <div class="planer_table_body" v-else>
          <div>Ничего не найдено(</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import axios from "axios";


const date_create = new Date()
date_create.setDate(date_create.getDate())
const date_create_res =
  date_create.getFullYear() +
  '-' +
  ('0' + (date_create.getMonth() + 1)).slice(-2) +
  '-' +
  ('0' + date_create.getDate()).slice(-2)

export default {
  components: {},
  data() {
    return {
      search_query: null,

      edit_modal: false,
      dublicateID: false,

      addCleanerID: 0,
      statusEdit: '',

      edit_plan_ID: null,
      edit_date: null,
      edit_in_work: null,
      edit_fio: null,
      edit_address: null,
      edit_status: null,
      edit_comment: null,
      edit_time: null,
      edit_ID: null,

      create_date: date_create_res,
      create_in_work: 0,
      create_fio: null,
      create_address: null,
      create_status: 0,
      create_comment: null,
      create_time: null,
      create_ID: null,

      modal_create: false,
      send_request: false,
      error_mess: null,

      plans: null,

      login: '',
      password: '',

    }
  },
  watch: {
    create_ID(newVal) {
      this.dublicateID = false;
      if (newVal) {
        for (let i in this.plans) {
          if (this.plans[i].plan_ID == this.create_ID) {
            this.dublicateID = true;
            break;
          }
        }
      }
    },
  },
  methods: {
    issetID() {

    },
    async delete_plan(id) {
      this.send_request = true;
      this.error_mess = null;
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'deletePlan',
              planID: id,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          this.modal_create = false;
          this.clearData();
          this.getPlans()
            .then(res => {
              this.plans = res;
            })
            .catch(res => {
              alert("Ошибка при получении данных с сервера")
            })
        }
        this.send_request = false;
      } catch (e) {
        this.send_request = false;
        console.log(e);
      }
    },
    open_edit(object) {
      this.edit_date = object.planerDate;
      this.edit_in_work = object.in_work;
      this.edit_fio = object.fio;
      this.edit_address = object.address;
      this.edit_status = object.status;
      this.edit_comment = object.comment;
      this.edit_time = object.planerTime;
      this.edit_ID = object.plan_ID;
      this.edit_plan_ID = object.ID;

      this.edit_modal = true;
    },
    checkDate(plan_date) {
      let today_date = new Date();
      plan_date = new Date(plan_date);

      let tomorrow_date = new Date(today_date.getTime() + (24 * 60 * 60 * 1000));

      if (today_date.toDateString() == plan_date.toDateString()) {
        return 1;
      } else if (tomorrow_date.toDateString() == plan_date.toDateString()) {
        return 2;
      } else {
        return 0;
      }
    },
    clearData() {
      this.create_fio = null;
      this.create_address = null;
      this.create_comment = null;
      this.create_time = null;
      this.create_ID = null;

      this.edit_date = null;
      this.edit_in_work = null;
      this.edit_fio = null;
      this.edit_address = null;
      this.edit_status = null;
      this.edit_comment = null;
      this.edit_time = null;
      this.edit_ID = null;
      this.edit_plan_ID = null;

      this.edit_modal = false;
      this.error_mess = null;

    },
    validateData(edit = false) {
      try {

        if (edit) {
          if (!this.edit_date) throw 'Неправильная дата';
          this.savePlan();
        } else {
          if (!this.create_date) throw 'Неправильная дата';
          this.addPlan();
        }


      } catch (e) {
        this.error_mess = e;
      }

    },
    getFormatDate(date) {
      const dateMatch = new Date(date);
      dateMatch.setDate(dateMatch.getDate());
      const dateMatchFormat = ('0' + dateMatch.getDate()).slice(-2) + '-' + ('0' + (dateMatch.getMonth() + 1)).slice(-2) + '-' + dateMatch.getFullYear();
      return dateMatchFormat;
    },
    ...mapMutations({}),
    ...mapActions({}),


    async savePlan() {
      this.send_request = true;
      this.error_mess = null;
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'savePlan',
              edit_plan_ID: this.edit_plan_ID,
              edit_date: this.edit_date,
              edit_in_work: Number(this.edit_in_work),
              edit_fio: this.edit_fio,
              edit_address: this.edit_address,
              edit_status: this.edit_status,
              edit_comment: this.edit_comment,
              edit_time: this.edit_time,
              edit_ID: this.edit_ID,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          this.modal_create = false;
          this.clearData();
          this.getPlans()
            .then(res => {
              this.plans = res;
            })
            .catch(res => {
              alert("Ошибка при получении данных с сервера")
            })
        }
        this.send_request = false;
      } catch (e) {
        this.send_request = false;
        console.log(e);
      }
    },
    async addPlan() {
      this.send_request = true;
      this.error_mess = null;
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'addPlan',
              create_date: this.create_date,
              create_in_work: Number(this.create_in_work),
              create_fio: this.create_fio,
              create_address: this.create_address,
              create_comment: this.create_comment,
              create_status: this.create_status,
              create_time: this.create_time,
              create_ID: this.create_ID,
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          this.modal_create = false;
          this.clearData();
          this.getPlans()
            .then(res => {
              this.plans = res;
            })
            .catch(res => {
              alert("Ошибка при получении данных с сервера")
            })
        }
        this.send_request = false;
      } catch (e) {
        this.send_request = false;
        console.log(e);
      }
    },
    async getPlans() {
      try {
        const response = await axios.post(
          'https://cleaning.basketstat.ru/api.php',
          {
            params: {
              request: 'getPlans',
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
            },
          },
        )
        if (response.data.status === 'success') {
          return response.data.plans;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getPlans()
      .then(res => {
        this.plans = res;
      })
      .catch(res => {
        alert("Ошибка при получении данных с сервера")
      })
  },

  computed: {
    searchArray() {
      if (!this.search_query) {
        return this.plans;
      }

      const query = this.search_query.toLowerCase();

      return this.issetIDAndAddress.filter(elem => {
        const planID = elem.plan_ID?.toString().toLowerCase();
        const address = elem.address?.toLowerCase();

        return (planID && planID.includes(query)) || (address && address.includes(query));
      });
    },


    issetIDAndAddress() {
      let second = this.plans.filter((elem) => elem.address);
      return this.plans.filter(
        (elem) => {
          if (elem.plan_ID && elem.address) return true;
        }
      )
    },

    ...mapState({
      newOrder: state => state.post.newOrder,
      orderActive: state => state.post.orderActive,
      orders: state => state.post.orders,
      cleaners: state => state.post.cleaners,
      isLoggedIn: state => state.post.isLoggedIn,
      error: state => state.post.error,
      GeneralExpenses: state => state.post.GeneralExpenses,

      ErrorClientName: state => state.post.ErrorClientName,
      ErrorOrderPrice: state => state.post.ErrorOrderPrice,

      searchQuery: state => state.post.searchQuery,
    }),
  }

}
</script>

<style scoped>
.table {
  overflow: auto;
  padding-bottom: 5px;
  margin-top: 10px;
  border-radius: 30px;
}

th {
  cursor: pointer;
  padding: 0px 5px 0px 5px;
}

th:hover {
  background: #ebebeb;
}

table {
  width: 100%;
  text-align: center;
  border-spacing: unset;
  border-radius: 10px;
  white-space: nowrap;
}

table thead {
  background: #d3d3d3;
  height: 60px;
}


.sortDown:after {
  content: "▼";
  font-size: 10px;
  color: #1E90FF;
}

.sortUp:after {
  content: "▲";
  font-size: 10px;
  color: #1E90FF;
}

.addOrder {
  border-radius: 30px;
  border: 1px solid gray;
  padding: 20px;
  margin-top: 20px;
}

.createOrder {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

input {
  padding: 10px;
  border-radius: 30px;
  border: 1px solid gray;
  margin: 10px;
  max-height: 40px;
  font-size: 17px;
  text-align: center;
}

textarea {
  padding: 5px;
  border-radius: 30px;
  border: 1px solid gray;
  margin: 10px;
  text-align: center;
}

.select-cleaner {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.select-cleaner:only-child {
  display: flex;
}

.error {
  border: 2px solid red;
}

button {
  padding: 10px;
  width: 150px;
  border-radius: 20px;
  border: none;
  background: #0072ff;
  color: white;
}

.cleanerSelect {
  border: 1px solid gray;
  padding: 5px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.cleanerSelect input {
  margin: 0;
}

.main-info div {
  margin-bottom: 10px;
}

.success {
  background: #c7ffc7;
  border: none !important;
  font-weight: bold;
  color: black;
}

.typeOrderOne {
  background: #6b6b6b;
  border: none;
  font-weight: bold;
  color: #ffffff;
}

.typeOrderTwo {
  background: #009bff;
  border: none;
  font-weight: bold;
  color: #ffffff;
}

.typeOrderThree {
  background: #fffa00;
  border: none;
  font-weight: bold;
  color: black;
}

.select_cleaner {
  height: 40px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.salary_cleaner input {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 0px 0px 0px 10px;
  border-radius: 5px;
}

.list_cleaners {
  width: 100%;
  max-height: 300px;
  overflow: auto;
  white-space: nowrap;
  min-width: 140px;
  margin-bottom: 50px;
}

.list_cleaners_cleaner {
  border: 1px solid #e9e9e9;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cleaner_selected {
  background: #1b00ff;
  color: white;
  border: none;
}

.hidden {
  display: none;
}

.alert {
  transition: all .5s;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  padding: 10px 30px 10px 30px;
  background: #87ff87ad;
  border-radius: 5px;
}

.salary_cleaner_element {
  position: relative;
  width: 100%;
}

.salary_cleaner_element_name {
  position: absolute;
  top: 0px;
  left: 4px;
  border-radius: 5px 0px 0px 5px;
  padding: 5px 0px 4px 3px;
  background: #dddddd;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
}

.salary_cleaner_element input {
  text-align: end;
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
}

@media (max-width: 440px) {
  .select-cleaner {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.order_details_back {
  background-color: #0000004a;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.order_details {
  max-width: 500px;
  width: 100%;
  min-height: 300px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: 90%;
  padding: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;

}

.fade-leave-active {
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.order_details_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order_details_header span {
  width: 100%;
}

.order_details_header span:nth-of-type(1) {
  font-weight: bold;
  font-size: 15px;
}

.order_details_header span:nth-of-type(2) {
  font-size: 13px;
  color: gray;
}


.order_details input {
  padding: 3px;
  max-height: 40px;
  font-size: 13px;
  text-align: center;
  margin: 0;
  width: 100%;
}

.order_details label {
  font-size: 12px;
  color: gray;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order_details textarea {
  width: 100%;
  margin: 0;
}

.order_details_header select {
  width: 100%;
  border-radius: 30px !important;
  padding: 2px !important;
  text-align: center !important;
  font-size: 15px !important;
  height: 30px !important;
}

.order_details_cleaners {
  width: 100%;
}

.order_details_cleaners table thead {
  height: 10px;
}

.order_details_cleaners table td {
  border: 1px solid #dbdbdbbd;
}

.order_details_cleaners table tr {
  height: 40px;
}

.order_details_cleaners button {
  width: 80%;
  padding: 2px;
  font-size: 13px;
  height: 40px;
}

button.disable {
  background-color: #9c9c9c;
  color: #a8a8a8;
}

.new_salary {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  border-radius: 10px;
  background-color: white;
  flex-direction: column;
  padding: 30px;
}

.search_order {
  margin: 20px 0;
}

.search_order_elem label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search_order_elem label span {
  font-weight: bold;
  font-size: 20px;
}

.small_text {
  font-size: 12px;
  color: gray;
}

thead th {
  position: sticky;
  top: 0;
}

.modal_create_back {
  background-color: #00000063;
  width: 100%;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
}

.modal_create {
  width: 310px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 10px;
}

.modal_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
}

.modal_create_info {
  width: 100%;
}

.modal_create_info label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #737373;
  font-weight: bold;
}

.modal_create_info input {
  width: 100%;
  border-radius: 5px;
  margin: 0;
  text-align: start;
  font-size: 17px;
  padding: 15px 5px;
}

.planer_table_head {
  display: flex;
  width: 100%;
}


.modal_create button {
  width: 100%;
  margin-top: 20px;
}

.modal_create button.send_request {
  background-color: gray;
  pointer-events: none;
}

.error_mess {
  font-weight: bold;
  color: red;
  width: 100%;
  text-align: center;
}


.planer_table_body {
  display: flex;
  flex-wrap: wrap;
}

.planer_table_body_elem {
  position: relative;
  width: 300px;
  display: flex;
  padding: 30px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px;
  box-shadow: 0px 0px 2px 0px black;
  border-radius: 20px;
}

.elem_id {
  position: absolute;
  left: -1px;
  top: -2px;
  background-color: #9a9cff;
  border-radius: 15px 0px 30px 0px;
  padding: 5px;
  padding-right: 20px;
  white-space: nowrap;
}

.plan_date {
  position: absolute;
  right: 10px;
  top: 0;
}

.planer_table_body_elem.orange {
  background-color: #ffa50054;
}

.planer_table_body_elem.red {
  background-color: #ff8b8b54;
}

.planer_table_body_elem.green {
  background-color: #00dd00;
}

.green_text {
  color: #00dd00;
}

.red_text {
  color: red;
}

.elem_title {
  font-weight: bold;
}

.edit_modal_back {
  background-color: #00000045;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_modal {
  padding: 10px;
  display: flex;
  position: relative;
  width: 300px;
  min-height: 400px;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
}

.close_modal {
  position: absolute;
  right: 10px;
  top: 10px;
}

select {
  padding: 10px;
}

@media (max-width: 996px) {
  .planer_table_body_elem {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .planer_table_body_elem {
    width: 100%;
  }
}

.elem_status {
  position: absolute;
  right: -1px;
  bottom: -1px;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0px 0px 3px 0px black;
}

.search_plans input {
  width: 100%;
  border-radius: 5px;
  margin: 10px 0;
}

.in_work {
  position: absolute;
  left: -3px;
  bottom: -4px;
  background-color: #10ff00;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
</style>
