import axios from 'axios'

const dateMatch = new Date()
dateMatch.setDate(dateMatch.getDate())
const dateMatchFormat =
  dateMatch.getFullYear() +
  '-' +
  ('0' + (dateMatch.getMonth() + 1)).slice(-2) +
  '-' +
  ('0' + dateMatch.getDate()).slice(-2)

export const postModule = {
  state: () => ({
    loading: false,

    orderActive: '',

    orders: [],
    ordersCleaners: [],

    newCleaner: '',
    cleaners: [],
    operators: [],

    ErrorClientName: false,
    ErrorOrderPrice: false,
    ErrorCleanerName: false,

    ErrorExpense: false,
    ErrorCostExpense: false,
    error: false,
    GeneralExpenses: [],
    isLoggedIn: false,
    userRoot: null, // 1 - admin (all root), 2 - 
    userID: 0,
    newOrder: {
      OrderDate: dateMatchFormat,
      ClientName: '',
      OrderAdress: '',
      OrderLink: 0,
      ClientPhone: '',
      PercentageBroker: '',
      OrderPrice: '',
      OrderComment: '',
      OrderExpenses: '',
      NameExpense: '',
      OrderCleanersID: [],
      OrderCleanersSalary: [],
      OrderCleaners: [],
      OurSite: false,
      Operator: 0,
      OrderID: null,
      OrderType: 'Клининг',
    },
  }),
  getters: {},
  mutations: {
    setLoading(state, bool) {
      state.loading = bool
    },
    setError(state, error) {
      state.error = error
    },
    setIsLoggedIn(state, obj) {
      state.isLoggedIn = obj.isLoggedIn
      state.userRoot = obj.userRoot
      state.userID = obj.userID
    },
    setExit(state) {
      state.isLoggedIn = false
      state.userRoot = null

    },
    setCleaners(state, cleaners) {
      state.cleaners = cleaners
    },
    setOperators(state, operators) {
      state.operators = operators
    },
    setGeneralExpenses(state, GeneralExpenses) {
      state.GeneralExpenses = GeneralExpenses
    },
    setOrders(state, orders) {
      state.orders = orders
    },
    setNewCleaner(state, newCleaner) {
      state.newCleaner = newCleaner
    },
    setExpenses(state, expenses) {
      state.expenses = expenses
    },

    setOrderActive(state, orderActive) {
      state.orderActive = orderActive
    },


    setErrorClientName(state, ErrorClientName) {
      state.ErrorClientName = ErrorClientName
    },
    setErrorOrderPrice(state, ErrorOrderPrice) {
      state.ErrorOrderPrice = ErrorOrderPrice
    },
    setErrorCleanerName(state, ErrorCleanerName) {
      state.ErrorCleanerName = ErrorCleanerName
    },

    setErrorCostExpense(state, ErrorCostExpense) {
      state.ErrorCostExpense = ErrorCostExpense
    },

    setErrorExpense(state, ErrorExpense) {
      state.ErrorExpense = ErrorExpense
    },

    nuleNewOrder(state) {
      state.newOrder.ClientName = ''
      state.newOrder.OrderAdress = ''
      state.newOrder.ClientPhone = ''
      state.newOrder.PercentageBroker = ''
      state.newOrder.OrderPrice = ''
      state.newOrder.OrderComment = ''
      state.newOrder.OrderExpenses = ''
      state.newOrder.OrderLink = 0
      state.newOrder.NameExpense = ''
      state.newOrder.OrderCleanersID = []
      state.newOrder.OrderCleanersSalary = []
      state.newOrder.OrderCleaners = []
      state.newOrder.OurSite = false
      state.newOrder.OrderID = null
      state.newOrder.Operator = false
    },
  },
  actions: {
    async keyExist({ commit }) {
      commit('setIsLoggedIn', true)
    },
    async entry({ commit, dispatch }, obj) {
      try {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'entry',
                login: obj.login,
                password: obj.password,
                entry_key: obj.entry_key,
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getOrders({ state, commit }) {
      try {
        commit('setLoading', true)
        setTimeout(async () => {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'getOrders',
                userRoot: state.userRoot,
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )

          commit('setOrders', response.data)
          commit('setLoading', false)
        }, 0)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },

    async getCleaners({ state, commit }) {
      try {
        commit('setLoading', true)
        setTimeout(async () => {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'getCleaners',
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )

          commit('setCleaners', response.data)
        }, 0)
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async getOperators({ state, commit }) {
      try {
        commit('setLoading', true)
        setTimeout(async () => {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'getOperators',
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )

          commit('setOperators', response.data)
        }, 0)
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async getGeneralExpenses({ state, commit }) {
      try {
        commit('setLoading', true)
        setTimeout(async () => {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'getGeneralExpenses',
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )

          commit('setGeneralExpenses', response.data)
          commit('setLoading', false)
        }, 0)
      } catch (e) {
        console.log(e)
      } finally {
      }
    },

    async addOrder({ state, commit, dispatch }, OrderCleaners) {
      try {
          const response = await axios.post(
            'https://cleaning.basketstat.ru/api.php',
            {
              params: {
                request: 'addOrder',
                newOrder: {
                  OrderDate: state.newOrder.OrderDate,
                  ClientName: state.newOrder.ClientName,
                  OrderAdress: state.newOrder.OrderAdress,
                  ClientPhone: state.newOrder.ClientPhone,
                  OrderPrice: state.newOrder.OrderPrice,
                  PercentageBroker: state.newOrder.PercentageBroker,
                  OrderComment: state.newOrder.OrderComment,
                  OrderExpenses: state.newOrder.OrderExpenses,
                  OrderLink: state.newOrder.OrderLink,
                  NameExpense: state.newOrder.NameExpense,
                  OrderCleaners: OrderCleaners,
                  OurSite: state.newOrder.OurSite,
                  OrderID: state.newOrder.OrderID,
                  Operator: state.newOrder.Operator,
                  OrderType: state.newOrder.OrderType,
                },
              },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
              },
            },
          )
          if(response.data.status === "success"){
            return true;
          }else{
            return false;
          }
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async addCleaner({ state, commit, dispatch }, cleanerName) {
      if (cleanerName != '') {
        try {
          setTimeout(async () => {
            const response = await axios.post(
              'https://cleaning.basketstat.ru/api.php',
              {
                params: {
                  request: 'addCleaner',
                  cleanerName: cleanerName,
                },
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                },
              },
            )
          }, 0)
        } catch (e) {
          console.log(e)
        } finally {
          dispatch('getCleaners')
        }
      } else {
        commit('setErrorCleanerName', 'true')
      }
    },

    async addExpense({ state, commit, dispatch }, obj) {
      if (obj.costExpense != '') {
        if (obj.nameExpense != '') {
          try {
            setTimeout(async () => {
              const response = await axios.post(
                'https://cleaning.basketstat.ru/api.php',
                {
                  params: {
                    request: 'addExpense',
                    costExpense: obj.costExpense,
                    nameExpense: obj.nameExpense,
                    ExpenseDate: obj.expenseDate,
                  },
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'NMklanfosfdvso#!#8997^Qwqrwqr99671',
                  },
                },
              )
            }, 0)
          } catch (e) {
            console.log(e)
          } finally {
            setTimeout(async () => {
              dispatch('getGeneralExpenses')
            }, 1200)
          }
        } else {
          commit('setErrorExpense', 'true')
        }
      } else {
        commit('setErrorCostExpense', 'true')
      }
    },

    arrayPlus({ state, dispatch, commit }) {
      if (state.newOrder.ClientName != '') {
        let massive = []
        let znachenie = []
        for (let key in state.newOrder.OrderCleanersID) {
          znachenie = {
            cleanerName: state.newOrder.OrderCleanersID[key],
            cleanerSalary: state.newOrder.OrderCleanersSalary[key],
          }
          massive.push(znachenie)
        }
        return dispatch('addOrder', massive);
      } else {
        commit('setErrorClientName', 'true');
        return false;
      }
    },
  },
  namespaced: true,
}
